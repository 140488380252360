import {environment} from "@/auth/environment";

export default class EmployeeService {
    // Will be used by this service for making API calls
    axiosIns = null
    useJwt = null
    jwtConfig = null


    constructor(useJwt) {
        this.useJwt = useJwt
        this.jwtConfig = useJwt.jwtConfig
        this.axiosIns = useJwt.axiosIns

    }


    employees(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getEmployees/" + company_id, ...args)
    }

    employee(employee_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/employee/getEmployee/" + employee_id + "?company_id=" + company_id)
    }

    update_employee(employee_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateEmployee/" + employee_id, data)
    }

    import_employees(data) {
        return this.axiosIns.post(environment.baseURL + "/employee/importEmployees/", data)
    }

    delete_employee(employee_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/deleteEmployee/" + employee_id, data)
    }

    documents(employee_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getEmployeeDocuments/" + employee_id, ...args)
    }

    courses(employee_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getCourses/" + employee_id, ...args)
    }

    document(course_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getEmployeeDocument/" + course_id, ...args)
    }

    update_document(course_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateEmployeeDocument/" + course_id, data)
    }

    delete_document(document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/deleteEmployeeDocument/" + document_id, data)
    }

    course(course_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getCourse/" + course_id, ...args)
    }

    medicals(employee_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getMedicalCheckups/" + employee_id, ...args)
    }

    medical(course_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getMedicalCheckup/" + course_id, ...args)
    }


    update_course(course_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateCourse/" + course_id, data)
    }

    update_medical(course_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateMedicalCheckup/" + course_id, data)
    }

    delete_course(document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/deleteCourse/" + document_id, data)
    }

    delete_medical(document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/deleteMedicalCheckup/" + document_id, data)
    }

    roles(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getRoles/" + company_id, ...args)
    }

    role(role_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/employee/getRole/" + role_id + "?company_id=" + company_id)
    }

    update_role(role_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateRole/" + role_id, data)
    }

    delete_role(role_id, data) {
        return this.axiosIns.get(environment.baseURL + "/employee/deleteRole/" + role_id, data)
    }

    departments(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getDepartments/" + company_id, ...args)
    }

    department(department_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/employee/getDepartment/" + department_id + "?company_id=" + company_id)
    }

    update_department(department_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateDepartment/" + department_id, data)
    }

    delete_department(department_id, data) {
        return this.axiosIns.get(environment.baseURL + "/employee/deleteDepartment/" + department_id, data)
    }

    risks(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getRisks", ...args)
    }

    risk(risk_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/employee/getRisk/" + risk_id )
    }

    update_risk(risk_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateRisk/" + risk_id, data)
    }

    delete_risk(risk_id, data) {
        return this.axiosIns.get(environment.baseURL + "/employee/deleteRisk/" + risk_id, data)
    }

    ppes(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getPPEs", ...args)
    }

    ppe(ppe_id) {
        return this.axiosIns.get(environment.baseURL + "/employee/getPPE/" + ppe_id )
    }

    update_ppe(ppe_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updatePPE/" + ppe_id, data)
    }

    delete_ppe(ppe_id, data) {
        return this.axiosIns.get(environment.baseURL + "/employee/deletePPE/" + ppe_id, data)
    }


    role_templates(...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getRoleTemplates" , ...args)
    }

    role_template(role_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/employee/getRoleTemplate/" + role_id + company_id)
    }

    update_role_template(role_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateRoleTemplate/" + role_id, data)
    }

    delete_role_template(role_id, data) {
        return this.axiosIns.get(environment.baseURL + "/employee/deleteRoleTemplate/" + role_id, data)
    }


    assignments(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/employee/getAssignments", ...args)
    }

    assignment(risk_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/employee/getAssignment/" + risk_id )
    }

    update_assignment(risk_id, data) {
        return this.axiosIns.post(environment.baseURL + "/employee/updateAssignment/" + risk_id, data)
    }

    delete_assignment(risk_id, data) {
        return this.axiosIns.get(environment.baseURL + "/employee/deleteAssignment/" + risk_id, data)
    }

}
