<template>
  <b-card-actions ref="card" title="" action-refresh @refresh="refresh">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon"/>
          <span>  {{ $t('Profile') }}
</span>
        </template>
        <div>

          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>

              <!-- Field: Name -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    :label="$t('First Name')"
                    label-for="first_name"
                >
                  <b-form-input
                      id="first_name"
                      v-model="user.name"
                      :disabled="allEditDisabled"
                      @keyup="user.name=titleCase(user.name)"
                  />
                </b-form-group>
              </b-col>


              <!-- Field: Surname -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    :label="$t('Last Name')"
                    label-for="last_name"
                >
                  <b-form-input
                      id="last_name"
                      v-model="user.surname"
                      :disabled="allEditDisabled"
                      @keyup="user.surname=titleCase(user.surname)"
                  />
                </b-form-group>
              </b-col>


            </b-row>

            <!-- Field: Email -->
            <b-row>

              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    :label="$t('Email')"
                    label-for="email"
                >
                  <b-form-input
                      id="email"
                      v-model="user.email"
                      :disabled="allEditDisabled"

                  />
                </b-form-group>
              </b-col>


              <!-- Field: Role -->
              <b-col
                  cols="12"
                  md="6"
                  v-if="isAdmin"
              >
                <b-form-group
                    :label="$t('Access Permission')"
                    label-for="user-role"
                >
                  <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :text="currentRole"
                      variant="outline-primary"
                      block
                      :disabled="allEditDisabled"

                  >
                    <b-dropdown-item
                        v-for="role in roles"
                        :key="role"
                        @click="setRole(role)"
                    >
                      <span class="ml-50">{{ role }}</span>
                    </b-dropdown-item>

                  </b-dropdown>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <!-- Field: Password -->

              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    :label="$t('Password')"
                    label-for="password"
                >
                  <b-form-input
                      id="password"
                      v-model="new_password"
                      type="password"
                      :disabled="allEditDisabled"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Password Confirm-->

              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    :label="$t('Confirm password')"
                    label-for="password_confirm"
                >
                  <b-form-input
                      id="password_confirm"
                      v-model="new_password_confirm"
                      type="password"
                      :disabled="allEditDisabled"

                  />
                </b-form-group>
              </b-col>


            </b-row>
            <b-alert
                variant="primary"
                show
                class="alert-danger"
                v-if="!enable_submit"
            >
              <div class="alert-body ">
                <span><strong>{{ $t('Note') }}:</strong> {{ error_message }}</span>
              </div>
            </b-alert>


          </b-form>


          <!-- PERMISSION TABLE -->
          <b-card
              no-body
              class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                    icon="LockIcon"
                    size="18"
                />
                <span class="align-middle ml-50"> {{ $t('Permissions') }}</span>
              </b-card-title>
            </b-card-header>
            <b-table
                striped
                responsive
                class="mb-0"
                ref="permissionTable"
                :fields="permission_fields"
                :items="permissionsData"
            >
              <template #cell(name)="row">
                {{ row.item.name }}
              </template>
              <template #cell(read)="row">
                <b-form-checkbox :disabled="isDisabled(row,'read')"
                                 v-model="row.item.read"></b-form-checkbox>
              </template>
              <template #cell(write)="row">
                <b-form-checkbox :disabled="isDisabled(row,'write')"
                                 v-model="row.item.write"></b-form-checkbox>
              </template>


            </b-table>
          </b-card>


          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="handleSubmit()"
              :disabled="!enable_submit || allEditDisabled"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="back($event)"
          >
            {{ $t('Cancel') }}


          </b-button>
        </div>
      </b-tab>

    </b-tabs>

  </b-card-actions>


</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BCardText,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BFormTextarea,
  BTable,
  BTabs,
  BTab,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BAlert,
  BCardTitle, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EmployeeService from "@/basic/employeeService";
import useUsersList from "@/views/apps/user/users-list/useUsersList";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import vSelect from 'vue-select'
import CoursesListComponent from "@/views/table/vue-good-table/CoursesListComponent";
import MedicalCheckupsListComponent from "@/views/table/vue-good-table/MedicalCheckupsListComponent";
import UserService from "@/basic/userService";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import UsersListComponent from "@/views/table/vue-good-table/UsersListComponent";
import ability from "@/libs/acl/ability";

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BAvatar,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BMedia,
    BTabs,
    BDropdown,
    BTab,
    BDropdownItem,
    flatPickr,
    Spacer,
    vSelect,
    CoursesListComponent,
    MedicalCheckupsListComponent,
    BFormFile,
    Cropper,
    UsersListComponent,
    BAlert,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      user: {
        name: "",
        surname: "",
        email: "",
        role: ""

      },
      user_modules: [],
      roles: ['client', 'admin'],
      company_permissions_data: [],
      permissionsData: [],
      permission_fields: [{key: 'name', label: i18n.t('Name'),}, {key: 'read', label: i18n.t('Read'),}, {
        key: 'write',
        label: i18n.t('Write')
      }],

      variant: "light",
      opacity: 0.85,
      blur: "2px",
      crop: {
        src: "",
        type: "image/jpg",
      },
      new_password: "unsetunset",
      new_password_confirm: "unsetunset",
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: false,
      allEditDisabled: (!ability.can("manage", "Basic Admin") && (!this.isAdmin)),
      users_disabled: true,
      company_id: this.$route.params.company_id,
      company_modules: [],
    }
  },
  computed: {
    currentRole() {
      return this.user.role
    },
    isAdmin() {
      return this.userData && this.userData.role === 'admin';

    },

    enable_submit() {
      return this.error_message.length === 0;
    },
    error_message() {
      if (this.new_password.length < 8) {
        return i18n.t('The password should be at least 8 chars long')

      } else if (this.new_password !== this.new_password_confirm) {
        return i18n.t('The confirmation password doesn\'t match')
      }
      return ""
    }

  },
  methods: {
    isDisabled(row, access) {
      console.log("isDisabled row", row)
      console.log("isDisabled access", access)
      console.log('company', this.userCompany)
      console.log('company_permissions_data', this.company_permissions_data)
      let company_enabled = this.company_permissions_data.find(item => item.module_id === row.item.module_id)
      console.log('company_enabled read', company_enabled.read)
      console.log('company_enabled write', company_enabled.write)
      return (!this.isAdmin) && (this.editDisabled || row.item.myself || this.allEditDisabled || company_enabled.write === false)

      //return false;
    },
    refresh() {

      const userService = new UserService(useJwt)

      let user_id = this.$route.params.id;
      let company_id = this.$route.params.company_id;
      this.$refs["card"].showLoading = true


      userService.getUserById(user_id, company_id).then(response => {
        if (user_id !== 'new') {
          this.user = response.data.data;
          this.users_disabled = false
        } else {
          this.new_password = ""
          this.new_password_confirm = ""
        }
        this.user_modules = response.data.data.modules;
        this.company_modules = response.data.data.company_modules;


        this.permissionsData = this.user_modules.map(function (value, index) {
          return {
            id: value.pivot.id,
            name: value.name,
            read: value.pivot.read === 1,
            write: value.pivot.write === 1,
            module_id: value.id,
            user_id: value.pivot.user_id,
            myself: false
          }
        })
        this.company_permissions_data = this.company_modules.map(function (value, index) {
          return {
            id: value.module.id,
            name: value.module.name,
            read: value.read ==1,
            write: value.write ==1,
            module_id: value.module_id,
            module: value,
          }
        })

        for (let permissionDatum of this.permissionsData) {
          if (permissionDatum.user_id === this.userData.id) {
            if (this.userData.role !== 'admin') {
              this.editDisabled = (!permissionDatum.write);
            }
            permissionDatum.myself = true;
          }

        }
        console.log('permissionsData', this.permissionsData)

        this.$refs["card"].showLoading = false


      })


    },
    async back(e) {
      this.$router.push({name: 'companies'})

    },


    handleUpload() {
      console.log("UPLOAD 1")


      console.log("UPLOAD 2")

      let filevalue = this.avatar_file;
      console.log('handleUpload filevalue', filevalue)

      var filePath = filevalue.name;
      console.log('handleUpload filePath', filevalue.name)


      let reader = new FileReader();

      reader.onload = (e) => {
        console.log('readAsDataURL', e.target.result);
        this.filedata = e.target.result
        const data_upload = {
          filedata: this.filedata,
          filename: filePath
        };
        console.log("UPLOAD 2", filedata)

        this.user.photo = filedata;

      };

      reader.readAsDataURL(this.avatar_file);


    },


    urltoFile(url, filename, mimeType) {
      return fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], filename, {type: mimeType});
          });
    },

    titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    },

    async handleSubmit(e) {
      const employeeService = new EmployeeService(useJwt)
      console.log("inputImageRenderer", this.inputImageRenderer)
      this.submitted = true;
      //const valid = await this.$validator.validate();
      const valid = true;

      if (!valid) {
        this.$vs.notify({
          title: this.$t("create_admin.FailedToCreateAdmin"),
          text: this.$t("create_admin.ConfirmPasswordFailed"),
          color: "danger",
          position: "top-center"
        });
        this.submitted = false;
        return false;
      }

      this.$refs["card"].showLoading = true
      if (this.new_password_confirm === "unsetunset") {

      }
      const data = {
        user: this.user,
        permissions: this.permissionsData,
        new_password: this.new_password,
        new_password_confirm: this.new_password_confirm,
        company_id: this.company_id,
      };


      try {
        console.log('data', data)
        let user_id = this.$route.params.id;
        const userService = new UserService(useJwt)

        userService.update_user(user_id, data).then(response => {
          console.log("response", response)
//          this.refresh();
          this.$refs["card"].showLoading = false
          if (response.data.status === 'success') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('User'),
                icon: 'AlertIcon',
                variant: 'success',
                text: i18n.t('User saved with success'),
              },
            })
            this.$router.push('/admin/company/' + this.company_id)

          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('User not updated'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: i18n.t(response.data.message),
              },
            })
            return false;

          }

        })

        //const response = await createUpdateUser(data);
        const response = {status: 200, data: {id: 1}}
        /*this.$vs.notify({
          title: this.$t("Success"),
          text: this.user_id ? this.$t('UserUpdatedSuccessfull') : this.$t('UserCreatedSucessfull'),
          color: "success",
          position: "top-center"
        });if (response.status === 200 && response.data.id)
          this.$router.push({name: 'ViewUser', params: {id: response.data.id}});

*/

      } catch (error) {
        /*   this.$vs.notify({
             title: this.$t("create_admin.FailedToCreateAdmin"),
             text: error,
             color: "danger",
             position: "top-center"
           }); */
      }
      this.submitted = false;
    },
    setRole(role) {
      this.user.role = role;
    },
    setDepartment(department) {
      this.department_new_id = department.id;
    },
  },
  mounted() {
    this.refresh()
    console.log("isAdmin", this.isAdmin);
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()


    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.avatar = base64
      console.log("avatar", props.avatar)
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
